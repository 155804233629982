import { ShowUsuariosLogService } from './service/show/show-usuarios-logs.service';
import { CostesProductosComponent } from './view/main/9_costes/costes-productos/costes-productos.component';
import {ShowCrmGuardService } from './service/show/show-crm-guard.service';
import { CrmFormComponent } from './view/main/2_fincas/crm/crm-form/crm-form.component';
import { CrmComponent } from './view/main/2_fincas/crm/cmr.component';
// eslint-disable-next-line max-len
import { TareasMtoCespedFormComponent } from './view/main/5_tareas/tareas-mto-cesped-cavado/tareas-mto-cesped-cavado-form/tareas-mto-cesped-form.component';
import { TransaccionesComponent } from './view/main/XI_reto/transacciones/transacciones.component';
import { EntidadesComponent } from './view/main/XI_reto/entidades/entidades.component';
import {FertilizantesFormComponent} from './view/main/5_tareas/fertilizantes/fertilizantes-form/fertilizantes-form.component';
import {FincasComponent} from './view/main/2_fincas/fincas.component';
import {FincaComponent} from './view/main/2_fincas/finca/finca.component';
import {ParcelasComponent} from './view/main/2_fincas/parcelas/parcelas.component';
import {SectoresComponent} from './view/main/2_fincas/sectores/sectores.component';
import {TratamientosFromComponent} from './view/main/7_tratamientos/tratamientos-form/tratamientos-from.component';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './view/login/login.component';
import {AuthGuardService as AuthGuard} from './service/auth/auth-guard.service';
import {DashboardComponent} from './view/dashboard/dashboard.component';
import {VentasComponent} from 'src/app/view/main/3_ventas/ventas/ventas.component';
import {InicioComponent} from './view/main/0_inicio/inicio.component';
import {TrabajadoresComponent} from './view/main/4_trabajadores/trabajadores.component';
import {TareasComponent} from './view/main/5_tareas/tareas.component';
import {VentasFormComponent} from './view/main/3_ventas/ventas/ventas-form/ventas-form.component';
import {MantenimientoComponent} from './view/main/4_trabajadores/mantenimiento/mantenimiento.component';
import {FertilizantesComponent} from './view/main/5_tareas/fertilizantes/fertilizantes.component';
import {FitosanitariosComponent} from './view/main/5_tareas/fitosanitarios/fitosanitarios.component';
import {TratamientosComponent} from './view/main/7_tratamientos/tratamientos.component';
import {ProductosComponent} from './view/main/6_productos/producto-add/productos.component';
import {FitosanitariosFormComponent} from './view/main/5_tareas/fitosanitarios/fitosanitarios-form/fitosanitarios-form.component';
import {ProductosFormComponent} from './view/main/6_productos/producto-add/productos-form/productos-form.component';
import {FincaFormComponent} from './view/main/2_fincas/finca/finca-form/finca-form.component';
import {ParcelasFormComponent} from './view/main/2_fincas/parcelas/parcelas-form/parcelas-form.component';
import {MantenimientoFormComponent} from './view/main/4_trabajadores/mantenimiento/mantenimiento-form/mantenimiento-form.component';
import {SectorFormComponent} from './view/main/2_fincas/sectores/sector-form/sector-form.component';
import {ClientesComponent} from './view/main/1_clientes/clientes.component';
import {ClientesFormComponent} from './view/main/1_clientes/clientes-form/clientes-form.component';
import {CompradoresComponent} from './view/main/3_ventas/compradores/compradores.component';
import {CompradoresFormComponent} from './view/main/3_ventas/compradores/compradores-form/compradores-form.component';
import {MaquinariaComponent} from './view/main/4_trabajadores/maquinaria/maquinaria.component';
import {MaquinariaFormComponent} from './view/main/4_trabajadores/maquinaria/maquinaria-form/maquinaria-form.component';
import {ProductosConsultasComponent} from './view/main/6_productos/productos-consultas/productos-consultas.component';
import {ProductoFertilizanteComponent} from './view/main/6_productos/producto-fertilizante/producto-fertilizante.component';
// eslint-disable-next-line max-len
import {ProductoFertilizanteFormComponent} from './view/main/6_productos/producto-fertilizante/producto-fertilizante-form/producto-fertilizante-form.component';
import {VisitasComponent} from './view/main/8_visitas/visitas/visitas.component';
import {VisitasFormComponent} from './view/main/8_visitas/visitas/visitas-form/visitas-form.component';
import {MapaAddComponent} from './view/main/2-1_mapas/mapa-add/mapa-add.component';
import {MapaSateliteComponent} from './view/main/2-1_mapas/mapa-satelite/mapa-satelite.component';
import {VisitasConsultasComponent} from './view/main/8_visitas/visitas-consultas/visitas-consultas.component';
import {VisitasVariedadMesComponent} from './view/main/8_visitas/visitas-variedad-mes/visitas-variedad-mes.component';
import { AnalisisComponent } from './view/main/2_fincas/analisis/analisis.component';
import { AnalisisFormComponent } from './view/main/2_fincas/analisis/analisis-form/analisis-form.component';
import { TareasBioComponent } from './view/main/5_tareas/tareas_bio/tareas_bio.component';
import { TareasBioFormComponent } from './view/main/5_tareas/tareas_bio/tareas_bio-form/tareas_bio-form.component';

/***** AGROGEST *****/
import { FichajesComponent } from './view/main/4_trabajadores/fichajes/fichajes.component';
import { PartesComponent } from './view/main/4_trabajadores/partes/partes.component';
import { OtrosComponent } from './view/main/5_tareas/otros/otros.component';
import { OtrosFormComponent } from './view/main/5_tareas/otros/otros-form/otros-form.component';
import { CostesTareasComponent } from './view/main/9_costes/costes-tareas/costes-tareas.component';

/*** Show / Don't show ***/
import { ShowAnalisisGuardService } from './service/show/show-analisis-guard.service';
import { ShowCostesGuardService } from './service/show/show-costes-guard.service';
import { ShowFichajesGuardService } from './service/show/show-fichajes-guard.service';
import { ShowPartesGuardService } from './service/show/show-partes-guard.service';
import { ShowMapasGuardService } from './service/show/show-mapas-guard.service';
import { ShowMapasSateliteGuardService } from './service/show/show-mapas-satelite-guard.service';
import { ShowTareasBioGuardService } from './service/show/show-tareas-bio-guard.service';
import { ShowTareasOtrosGuardService } from './service/show/show-tareas-otros-guard.service';
import { ShowVisitasGuardService } from './service/show/show-visitas-guard.service';
import { ShowVisitasConsultasGuardService } from './service/show/show-visitas-consultas-guard.service';
import { ShowVisitasVariedadMesGuardService } from './service/show/show-visitas-variedad-mes-guard.service';
import { StockComponent } from './view/stock/stock.component';
import { EntradasComponent } from './view/stock/entradas/entradas.component';
import { SalidasComponent } from './view/stock/salidas/salidas.component';
import { BalancesComponent } from './view/stock/balances/balances.component';
import { ShowStockGuardService } from './service/show/show-stock-guard.service';
import { StockProductosComponent } from './view/stock/productos/productos.component';
import { StockFormularioProductosComponent } from './view/stock/productos/stock-formulario-productos/stock-formulario-productos.component';
import { EntradasFormComponent } from './view/stock/entradas/entradas-form/entradas-form.component';
import { SalidasFormComponent } from './view/stock/salidas/salidas-form/salidas-form.component';
import { ShowVentasGuardService } from './service/show/show-ventas-guard.service';
import { VisitasSemanaComponent } from './view/main/8_visitas/visitas-semana/visitas-semana.component';
import { ShowVisitasSemanaGuardService } from './service/show/show-visitas-semana-guard.service';
import { TratamientosControlComponent } from './view/main/7_tratamientos/control/control.component';
import { TratamientosControlFormComponent } from './view/main/7_tratamientos/control/control-form/control-form.component';
import { ShowTratamientosControlGuardService } from './service/show/show-tratamientos-control-guard.service';
import { ShowClientesGuardService } from './service/show/show-clientes-guard.service';
import { CalendarioAbonadoComponent } from './view/main/5_tareas/calendario-abonado/calendario-abonado.component';
import { ShowCalendarioAbonadoService } from './service/show/show-calendario-abonado-guard.service';
import { ShowTareasFertilizantesGuardService } from './service/show/show-tareas-fertilizantes-guard.service';
import { ShowFincasGuardService } from './service/show/show-fincas-guard.service';
import { ShowAplicadoresGuardService } from './service/show/show-aplicadores-guard.service';
import { ShowProductosGuardService } from './service/show/show-productos-guard.service';
import { ShowTareasFitosanitariasGuardService } from './service/show/show-tareas-fitosanitarias-guard.service';
import { ShowSectoresGuardService } from './service/show/show-sectores-guard.service';
import { ShowTratamientosManezGuardService } from './service/show/show-tratamientos-manez-guard.service';
import { TrataManezComponent } from './view/main/7_tratamientos/trata-manez/trata-manez.component';
import { TrataManezFormComponent } from './view/main/7_tratamientos/trata-manez/trata-manez-form/trata-manez-form.component';
import { ShowTrabajadoresGuardService } from './service/show/show-trabajadores-guard.service';
import { CamposComponent } from './view/main/2_fincas/campos/campos.component';
import { CamposFormComponent } from './view/main/2_fincas/campos/campos-form/campos-form.component';
import { ShowCamposGuardService } from './service/show/show-campos-guard.service';
import { StatisticsComponent } from './view/main/0_inicio/statistics/statistics.component';
import { ShowEstadisticasGuardService } from './service/show/show-estadisticas-guard.service';
import { ProductosSimpleComponent } from './view/main/6_productos/producto-simple/productos-simple.component';
// eslint-disable-next-line max-len
import { ProductosSimpleFormComponent } from './view/main/6_productos/producto-simple/productos-simple-form/productos-simple-form.component';
import { ShowProductosSimpleGuardService } from './service/show/show-productos-simple-guard.service';
import { UsuariosComponent } from './view/main/X_usuarios/usuarios.component';
import { ShowUsuariosGuardService } from './service/show/show-usuarios-guard.service';
import { UsuariosFormComponent } from './view/main/X_usuarios/usuarios-form/usuarios-form.component';
import { ShowVademecumGuardService } from './service/show/show-vademecum-guard.service';
import { ControlRiegoComponent } from './view/main/5_tareas/control-riego/control-riego.component';
import { ShowControlRiegoGuardService } from './service/show/show-control-riego-guard.service';
import { CostesFertilizantesComponent } from './view/main/9_costes/costes-fertilizantes/costes-fertilizantes.component';
import { CostesFitosanitariosComponent } from './view/main/9_costes/costes-fitosanitarios/costes-fitosanitarios.component';
import { SiembraComponent } from './view/main/5_tareas/siembra/siembra.component';
import { SiembraFormComponent } from './view/main/5_tareas/siembra/siembra-form/siembra-form.component';
import { ShowTareasSiembraGuardService } from './service/show/show-tareas-siembra-guard.service';
import { VisitasSemanaFormComponent } from './view/main/8_visitas/visitas-semana/visitas-semana-form/visitas-semana-form.component';
import { VisitasTrasiegoComponent } from './view/main/8_visitas/visitas-trasiego/visitas-trasiego.component';
import { VisitasTrasiegoFormComponent } from './view/main/8_visitas/visitas-trasiego/visitas-trasiego-form/visitas-trasiego-form.component';
import { ConsultasComponent } from './view/main/4_trabajadores/consultas/consultas.component';
import { ShowConsultasGuardService } from './service/show/show-consultas-guard.service';
import { ControlSanitarioComponent } from './view/main/5_tareas/control-sanitario/control-sanitario.component';
import { ShowControlSanitarioGuardService } from './service/show/show-control-sanitario-guard.service';
// eslint-disable-next-line max-len
import { ControlSanitarioFormComponent } from './view/main/5_tareas/control-sanitario/control-sanitario-form/control-sanitario-form.component';
import { FitoLocalesComponent } from './view/main/5_tareas/fito-locales/fito-locales.component';
import { FitoLocalesFormComponent } from './view/main/5_tareas/fito-locales/fito-locales-form/fito-locales-form.component';
import { ShowFitoLocalesGuardService } from './service/show/show-fito-locales-guard.service';
import { RetoComponent } from './view/main/XI_reto/reto.component';
import { EntidadesFormComponent } from './view/main/XI_reto/entidades/entidades-form/entidades-form';
import { TransaccionesFormComponent } from './view/main/XI_reto/transacciones/transacciones-form/transacciones-form.component';
import { ShowRetoGuardService } from './service/show/show-reto-guard.service';
import { MapaVentuskyComponent } from './view/main/2-1_mapas/mapa-ventusky/mapa-ventusky.component';
import { ShowMapasVentuskyGuardService } from './service/show/show-mapas-ventusky-guard.service';
import { ProductoGanaderiaComponent } from './view/main/6_productos/producto-ganaderia/producto-ganaderia.component';
// eslint-disable-next-line max-len
import { ProductoGanaderiaFormComponent } from './view/main/6_productos/producto-ganaderia/producto-ganaderia-form/producto-ganaderia-form.component';
import { MapaRutasComponent } from './view/main/2-1_mapas/mapa-rutas/mapa-rutas.component';
import { MapaTratamientosComponent } from './view/main/2-1_mapas/mapa-tratamientos/mapa-tratamientos.component';
import { ShowTareasMtoGuardService } from './service/show/show-tareas-mto-guard.service';
import { TareasMtoPodaComponent } from './view/main/5_tareas/tareas-mto-poda/tareas-mto-poda.component';
import { TareasMtoPodaFormComponent } from './view/main/5_tareas/tareas-mto-poda/tareas-mto-poda-form/tareas-mto-poda-form.component';
import { TareasMtoCespedComponent } from './view/main/5_tareas/tareas-mto-cesped-cavado/tareas-mto-cesped.component';
import { TareasMtoLimpiezaGeneralComponent } from './view/main/5_tareas/tareas-mto-limpieza-general/tareas-mto-limpieza-general.component';
// eslint-disable-next-line max-len
import { TareasMtoLimpiezaGeneralFormComponent } from './view/main/5_tareas/tareas-mto-limpieza-general/tareas-mto-limpieza-general-form/tareas-mto-limpieza-general-form.component';
import { TareasMtoRiegoComponent } from './view/main/5_tareas/tareas-mto-riego/tareas-mto-riego.component';
import { TareasMtoRiegoFormComponent } from './view/main/5_tareas/tareas-mto-riego/tareas-mto-riego-form/tareas-mto-riego-form.component';
import { TareasMtoPiscinasComponent } from './view/main/5_tareas/tareas-mto-piscinas/tareas-mto-piscinas.component';
// eslint-disable-next-line max-len
import { TareasMtoPiscinasFormComponent } from './view/main/5_tareas/tareas-mto-piscinas/tareas-mto-piscinas-form/tareas-mto-piscinas-form.component';
import { TareasPlantacionComponent } from './view/main/5_tareas/tareas-plantacion/tareas-plantacion.component';
import { TareasPlantacionFormComponent } from './view/main/5_tareas/tareas-plantacion/tareas-plantacion-form/tareas-plantacion.component';
import { TareasAlquilerMaquinariaComponent } from './view/main/5_tareas/tareas-alquiler-maquinaria/tareas-alquiler-maquinaria.component';
// eslint-disable-next-line max-len
import { TareasAlquilerMaquinariaFormComponent } from './view/main/5_tareas/tareas-alquiler-maquinaria/tareas-alquiler-maquinaria-form/tareas-alquiler-maquinaria-form.component';
import { TareasEdificiosComponent } from './view/main/5_tareas/tareas-edificios/tareas-edificios.component';
import { TareasEdificiosFormComponent } from './view/main/5_tareas/tareas-edificios/tareas-edificios-form/tareas-edificios-form.component';
import { ShowACostesProductosGuardService } from './service/show/show-costes-productos-guard.service';
import { ConsultasLogsComponent } from './view/main/XII_consultas-logs/consultas-logs.component';
import { VisitasViverospereiraComponent } from './view/main/8_visitas/visitas-viverospereira/visitas-viverospereira.component';
// eslint-disable-next-line max-len
import { ViverosPereiraFormComponent } from './view/main/8_visitas/visitas-viverospereira/viveros-pereira-form/viveros-pereira-form.component';
import { ShowVisitasSeguimientoGuardService } from './service/show/show-visitas-seguimiento-guard.service';
import { AgroambientalesComponent } from './view/main/5_tareas/agroambientales/agroambientales.component';
import { AgroambientalesFormComponent } from './view/main/5_tareas/agroambientales/agroambientales-form/agroambientales-form.component';
import { ShowAgroambientalGuardService } from './service/show/show-agroambiental-guard.service';
import { CalendarioTareasComponent } from './view/main/5_tareas/calendario-tareas/calendario-tareas.component';
import { SubcontratosComponent } from './view/main/5_tareas/subcontratos/subcontratos.component';
import { SubcontratosFormComponent } from './view/main/5_tareas/subcontratos/subcontratos-form/subcontratos-form.component';
import { ShowSubcontratosGuardService } from './service/show/show-subcontratos-guard.service';
import { EcorregimesComponent } from './view/main/5_tareas/ecorregimes/ecorregimes.component';
import { EcorregimesFormComponent } from './view/main/5_tareas/ecorregimes/ecorregimes-form/ecorregimes-form.component';
import { ShowEcorregimesGuardService } from './service/show/show-ecorregimes-guard.service';
import { RegisterComponent } from './view/login/register/register.component';
import { ProductoBioFormComponent } from './view/main/6_productos/producto-bio/producto-bio-form/producto-bio-form.component';
import { ProductosBioComponent } from './view/main/6_productos/producto-bio/producto-bio.component';
import { RecogidasComponent } from './view/main/XIII_recogidas/recogidas.component';
import { RecogidasFormComponent } from './view/main/XIII_recogidas/recogidas-form/recogidas-form.component';
import { ShowRecogidasGuardService } from './service/show/show-recogidas-guard.service';
import { VisitasPedidosComponent } from './view/main/8_visitas/visitas-pedidos/visitas-pedidos.component';
import { VisitasPedidosFormComponent } from './view/main/8_visitas/visitas-pedidos/visitas-pedidos-form/visitas-pedidos-form.component';
import { ShowVisitasPedidosGuardService } from './service/show/show-visitas-pedidos-guard.service';

import type { Routes } from '@angular/router';
import { CRMConsultasComponent } from './view/main/2_fincas/crm-consultas/crm-consultas.component';
import { AlbaranesComponent } from './view/main/3_ventas/albaranes/albaranes.component';
import { AlbaranesFormComponent } from './view/main/3_ventas/albaranes/albaranes-form/albaranes-form.component';
import { ChoferFormComponent } from './view/main/3_ventas/albaranes/chofer/chofer-form/chofer-form.component';
import { ChoferComponent } from './view/main/3_ventas/albaranes/chofer/chofer.component';
import { ClienteAlbaranFormComponent } from './view/main/3_ventas/albaranes/cliente/cliente-form/cliente-form.component';
import { ClienteAlbaranComponent } from './view/main/3_ventas/albaranes/cliente/cliente.component';
import { AlbaranesMaquinariaComponent } from './view/main/3_ventas/albaranes/maquinaria/maquinaria.component';
import { AlbaranesMaquinariaFormComponent } from './view/main/3_ventas/albaranes/maquinaria/maquinaria/maquinaria-form.component';
import { ObraFormComponent } from './view/main/3_ventas/albaranes/obra/obra-form/obra-form.component';
import { ObraComponent } from './view/main/3_ventas/albaranes/obra/obra.component';
const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'login'},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'inicio'},
            {path: 'inicio', component: InicioComponent, canActivate: [AuthGuard]},

            {path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard, ShowEstadisticasGuardService]},

            {path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard, ShowUsuariosGuardService]},
            {path: 'crear-usuario', component: UsuariosFormComponent, canActivate: [AuthGuard, ShowUsuariosGuardService]},
            {path: 'editar-usuario/:id', component: UsuariosFormComponent, canActivate: [AuthGuard, ShowUsuariosGuardService]},
            {path: 'duplicar-usuario/:id', component: UsuariosFormComponent, canActivate: [AuthGuard, ShowUsuariosGuardService]},

            {path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard, ShowClientesGuardService]},
            {path: 'crear-cliente', component: ClientesFormComponent, canActivate: [AuthGuard, ShowClientesGuardService]},
            {path: 'editar-cliente/:id', component: ClientesFormComponent, canActivate: [AuthGuard, ShowClientesGuardService]},

            {path: 'fincas', component: FincasComponent, canActivate: [AuthGuard, ShowFincasGuardService]},
            {path: 'finca', component: FincaComponent, canActivate: [AuthGuard, ShowFincasGuardService]},
            {path: 'crear-finca', component: FincaFormComponent, canActivate: [AuthGuard, ShowFincasGuardService]},
            {path: 'editar-finca/:id', component: FincaFormComponent, canActivate: [AuthGuard, ShowFincasGuardService]},
            {path: 'duplicar-finca/:id', component: FincaFormComponent, canActivate: [AuthGuard, ShowFincasGuardService]},

            {path: 'parcelas', component: ParcelasComponent, canActivate: [AuthGuard,
                ShowFincasGuardService]},
            {path: 'crear-parcela', component: ParcelasFormComponent, canActivate: [AuthGuard,
                ShowFincasGuardService]},
            {path: 'editar-parcela/:id', component: ParcelasFormComponent, canActivate: [AuthGuard,
                ShowFincasGuardService]},
            {path: 'duplicar-parcela/:id', component: ParcelasFormComponent, canActivate: [AuthGuard,
                ShowFincasGuardService]},

            {path: 'sectores', component: SectoresComponent, canActivate: [AuthGuard,
                ShowSectoresGuardService]},
            {path: 'crear-sector', component: SectorFormComponent, canActivate: [AuthGuard,
                ShowSectoresGuardService]},
            {path: 'editar-sector/:id', component: SectorFormComponent, canActivate: [AuthGuard,
                ShowSectoresGuardService]},

            {path: 'analisis', component: AnalisisComponent, canActivate: [AuthGuard, ShowAnalisisGuardService]},
            {path: 'crear-analisis', component: AnalisisFormComponent, canActivate: [AuthGuard, ShowAnalisisGuardService]},
            {path: 'editar-analisis/:id', component: AnalisisFormComponent, canActivate: [AuthGuard, ShowAnalisisGuardService]},
            {path: 'duplicar-analisis/:id', component: AnalisisFormComponent, canActivate: [AuthGuard, ShowAnalisisGuardService]},
           
            {path: 'crm', component: CrmComponent, canActivate: [AuthGuard, ShowCrmGuardService]},
            {path: 'crear-crm', component: CrmFormComponent, canActivate: [AuthGuard, ShowCrmGuardService]},
            {path: 'editar-crm/:id', component: CrmFormComponent, canActivate: [AuthGuard, ShowCrmGuardService]},
            {path: 'crm-consultas', component: CRMConsultasComponent, canActivate: [AuthGuard, ShowCrmGuardService]},

            {path: 'campos', component: CamposComponent, canActivate: [AuthGuard, ShowCamposGuardService]},
            {path: 'crear-campo', component: CamposFormComponent, canActivate: [AuthGuard, ShowCamposGuardService]},
            {path: 'editar-campo/:id', component: CamposFormComponent, canActivate: [AuthGuard, ShowCamposGuardService]},


            {path: 'mapas', component: MapaAddComponent, canActivate: [AuthGuard, ShowMapasGuardService]},
            {path: 'mapas-rutas', component: MapaRutasComponent, canActivate: [AuthGuard, ShowMapasGuardService]},
            {path: 'mapas-tratamientos', component: MapaTratamientosComponent, canActivate: [AuthGuard, ShowMapasGuardService]},
            {path: 'satelite', component: MapaSateliteComponent, canActivate: [AuthGuard, ShowMapasSateliteGuardService]},
            {path: 'meteo', component: MapaVentuskyComponent, canActivate: [AuthGuard, ShowMapasVentuskyGuardService]},

            {path: 'ventas', component: VentasComponent, canActivate: [AuthGuard, ShowVentasGuardService]},
            {path: 'crear-venta', component: VentasFormComponent, canActivate: [AuthGuard, ShowVentasGuardService]},
            {path: 'editar-venta/:id', component: VentasFormComponent, canActivate: [AuthGuard, ShowVentasGuardService]},
            {path: 'duplicar-venta/:id', component: VentasFormComponent, canActivate: [AuthGuard, ShowVentasGuardService]},

            // VENTAS > COMERCIOS
            {path: 'compradores', component: CompradoresComponent, canActivate: [AuthGuard]},
            {path: 'crear-comprador', component: CompradoresFormComponent, canActivate: [AuthGuard]},
            {path: 'editar-comprador/:id', component: CompradoresFormComponent, canActivate: [AuthGuard]},
            {path: 'duplicar-comprador/:id', component: CompradoresFormComponent, canActivate: [AuthGuard]},
            
            // VENTAS > ALBARÀN
            {path: 'albaranes', component: AlbaranesComponent, canActivate: [AuthGuard]},
            {path: 'crear-albaran', component: AlbaranesFormComponent, canActivate: [AuthGuard]},
            {path: 'editar-albaran/:id', component: AlbaranesFormComponent, canActivate: [AuthGuard]},
            {path: 'duplicar-albaran/:id', component: AlbaranesFormComponent, canActivate: [AuthGuard]},
            
             // VENTAS > ALBARÀN > TABLAS-MAESTRAS
             {path: 'chofer', component: ChoferComponent, canActivate: [AuthGuard]},
             {path: 'crear-chofer', component: ChoferFormComponent, canActivate: [AuthGuard]},
             {path: 'editar-chofer/:id', component: ChoferFormComponent, canActivate: [AuthGuard]},
             {path: 'duplicar-chofer/:id', component: ChoferFormComponent, canActivate: [AuthGuard]},
 
             {path: 'albaran-cliente', component: ClienteAlbaranComponent, canActivate: [AuthGuard]},
             {path: 'crear-albaran-cliente', component: ClienteAlbaranFormComponent, canActivate: [AuthGuard]},
             {path: 'editar-albaran-cliente/:id', component: ClienteAlbaranFormComponent, canActivate: [AuthGuard]},
             {path: 'duplicar-albaran-cliente/:id', component: ClienteAlbaranFormComponent, canActivate: [AuthGuard]},
             
             {path: 'albaran-maquinaria', component: AlbaranesMaquinariaComponent, canActivate: [AuthGuard]},
             {path: 'crear-albaran-maquinaria', component: AlbaranesMaquinariaFormComponent, canActivate: [AuthGuard]},
             {path: 'editar-albaran-maquinaria/:id', component: AlbaranesMaquinariaFormComponent, canActivate: [AuthGuard]},
             {path: 'duplicar-albaran-maquinaria/:id', component: AlbaranesMaquinariaFormComponent, canActivate: [AuthGuard]},
 
             {path: 'obras', component: ObraComponent, canActivate: [AuthGuard]},
             {path: 'crear-obra', component: ObraFormComponent, canActivate: [AuthGuard]},
             {path: 'editar-obra/:id', component: ObraFormComponent, canActivate: [AuthGuard]},
             {path: 'duplicar-obra/:id', component: ObraFormComponent, canActivate: [AuthGuard]},

            // Recogidas
            {path: 'recogidas', component: RecogidasComponent, canActivate: [AuthGuard, ShowRecogidasGuardService]},
            {path: 'crear-recogida', component: RecogidasFormComponent, canActivate: [AuthGuard, ShowRecogidasGuardService]},
            {path: 'editar-recogida/:id', component: RecogidasFormComponent, canActivate: [AuthGuard, ShowRecogidasGuardService]},
            {path: 'duplicar-recogida/:id', component: RecogidasFormComponent, canActivate: [AuthGuard, ShowRecogidasGuardService]},

            // APLICADORES
            {path: 'trabajadores', component: TrabajadoresComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService]},

            {path: 'mantenimiento', component: MantenimientoComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService, ShowTrabajadoresGuardService]},
            {path: 'crear-mantenimiento', component: MantenimientoFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService, ShowTrabajadoresGuardService]},
            {path: 'editar-mantenimiento/:id', component: MantenimientoFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService, ShowTrabajadoresGuardService]},
            {path: 'duplicar-mantenimiento/:id', component: MantenimientoFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService, ShowTrabajadoresGuardService]},

            // APLICADORES MAQUINARIA
            {path: 'maquinaria', component: MaquinariaComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService]},
            {path: 'crear-maquinaria', component: MaquinariaFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService]},
            {path: 'editar-maquinaria/:id', component: MaquinariaFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService]},
            {path: 'duplicar-maquinaria/:id', component: MaquinariaFormComponent, canActivate: [AuthGuard,
                ShowAplicadoresGuardService]},

            {path: 'fichajes', component: FichajesComponent, canActivate: [AuthGuard, ShowFichajesGuardService]},
            {path: 'partes', component: PartesComponent, canActivate: [AuthGuard, ShowPartesGuardService]},
            {path: 'ropo_roma_regepa', component: ConsultasComponent, canActivate: [AuthGuard, ShowConsultasGuardService ]},

            {path: 'tareas', component: TareasComponent, canActivate: [AuthGuard]},

            // TAREAS > FERTILIZANTE
            {path: 'fertilizantes', component: FertilizantesComponent,
                canActivate: [AuthGuard, ShowTareasFertilizantesGuardService]},
            {path: 'crear-fertilizante-tarea', component: FertilizantesFormComponent,
                canActivate: [AuthGuard, ShowTareasFertilizantesGuardService]},
            {path: 'editar-fertilizante-tarea/:id', component: FertilizantesFormComponent,
                canActivate: [AuthGuard, ShowTareasFertilizantesGuardService]},
            {path: 'editar-fertilizante-tarea/:id/:deCalendario', component: FertilizantesFormComponent,
                canActivate: [AuthGuard, ShowTareasFertilizantesGuardService]},
            {path: 'duplicar-fertilizante-tarea/:id', component: FertilizantesFormComponent,
                canActivate: [AuthGuard, ShowTareasFertilizantesGuardService]},

            // TAREAS > Calendario Abonado
            {path: 'calendario-abonado', component: CalendarioAbonadoComponent, canActivate: [AuthGuard, ShowCalendarioAbonadoService]},
            {path: 'calendario-tareas', component: CalendarioTareasComponent},

            // TAREAS > FITOSANITARIOS
            {path: 'fitosanitarios', component: FitosanitariosComponent, canActivate: [AuthGuard,
                ShowTareasFitosanitariasGuardService]},
            {path: 'crear-fitosanitario', component: FitosanitariosFormComponent, canActivate: [AuthGuard,
                ShowTareasFitosanitariasGuardService]},
            {path: 'editar-fitosanitario/:id', component: FitosanitariosFormComponent, canActivate: [AuthGuard,
                ShowTareasFitosanitariasGuardService]},
            {path: 'editar-fitosanitario/:id/:deCalendario', component: FitosanitariosFormComponent, canActivate: [AuthGuard,
                ShowTareasFitosanitariasGuardService]},
            {path: 'duplicar-fitosanitario/:id', component: FitosanitariosFormComponent, canActivate: [AuthGuard,
                ShowTareasFitosanitariasGuardService]},

            //SUBCONTRATO
            {path: 'subcontratos', component: SubcontratosComponent, canActivate: [AuthGuard, ShowSubcontratosGuardService]},
            {path: 'crear-subcontratos', component: SubcontratosFormComponent, canActivate: [AuthGuard, ShowSubcontratosGuardService]},
            {path: 'editar-subcontratos/:id', component: SubcontratosFormComponent, canActivate: [AuthGuard, ShowSubcontratosGuardService]},
            {
                path: 'duplicar-subcontratos/:id', 
                component: SubcontratosFormComponent, 
                canActivate: [AuthGuard, ShowSubcontratosGuardService]
            },


            // TAREAS > BIO
            {path: 'bio', component: TareasBioComponent, canActivate: [AuthGuard, ShowTareasBioGuardService]},
            {path: 'crear-bio', component: TareasBioFormComponent, canActivate: [AuthGuard, ShowTareasBioGuardService]},
            {path: 'editar-bio/:id/:deCalendario', component: TareasBioFormComponent, canActivate: [AuthGuard, ShowTareasBioGuardService]},
            {path: 'duplicar-bio/:id', component: TareasBioFormComponent, canActivate: [AuthGuard, ShowTareasBioGuardService]},

            // TAREAS > MTO PODA
            {path: 'poda', component: TareasMtoPodaComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            {path: 'crear-poda', component: TareasMtoPodaFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            {path: 'editar-poda/:id', component: TareasMtoPodaFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            {path: 'duplicar-poda/:id', component: TareasMtoPodaFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            // TAREAS > CESPED CAVADO
            { path: 'cesped-cavado', component: TareasMtoCespedComponent,  canActivate: [AuthGuard, ShowTareasMtoGuardService] },    
            { path: 'crear-cesped-cavado', component: TareasMtoCespedFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { 
                path: 'editar-cesped-cavado/:id', 
                component: TareasMtoCespedFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },
            { 
                path: 'duplicar-cesped-cavado/:id', 
                component: TareasMtoCespedFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },    

            // TAREAS > LIMPIEZA GENERAL
            { 
                path: 'limpieza-general', 
                component: TareasMtoLimpiezaGeneralComponent,  
                canActivate: [AuthGuard, ShowTareasMtoGuardService] 
            },    
            { 
                path: 'crear-limpieza-general', 
                component: TareasMtoLimpiezaGeneralFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },
            { 
                path: 'editar-limpieza-general/:id', 
                component: TareasMtoLimpiezaGeneralFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },
            { 
                path: 'duplicar-limpieza-general/:id', 
                component: TareasMtoLimpiezaGeneralFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },    

            // TAREAS > RIEGO
            { path: 'riego', component: TareasMtoRiegoComponent,  canActivate: [AuthGuard, ShowTareasMtoGuardService] },    
            { path: 'crear-riego', component: TareasMtoRiegoFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { path: 'editar-riego/:id', component: TareasMtoRiegoFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { path: 'duplicar-riego/:id', component: TareasMtoRiegoFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},    

            // TAREAS > PISCINAS
            { path: 'piscinas', component: TareasMtoPiscinasComponent,  canActivate: [AuthGuard, ShowTareasMtoGuardService] },    
            { path: 'crear-piscina', component: TareasMtoPiscinasFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { path: 'editar-piscina/:id', component: TareasMtoPiscinasFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { 
                path: 'duplicar-piscina/:id', 
                component: TareasMtoPiscinasFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },    

            // TAREAS > PLANTACIÓN
            { 
                path: 'plantacion', 
                component: TareasPlantacionComponent,  
                canActivate: [AuthGuard, ShowTareasMtoGuardService] 
            },    
            { path: 'crear-plantacion', component: TareasPlantacionFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { path: 'editar-plantacion/:id', component: TareasPlantacionFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { 
                path: 'duplicar-plantacion/:id', 
                component: TareasPlantacionFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },    

            // TAREAS > ALQUILER MAQUINARIA
            { 
                path: 'alquiler-maquinaria', 
                component: TareasAlquilerMaquinariaComponent,  
                canActivate: [AuthGuard, ShowTareasMtoGuardService] 
            },    
            { 
                path: 'crear-alquiler-maquinaria', 
                component: TareasAlquilerMaquinariaFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },
            { 
                path: 'editar-alquiler-maquinaria/:id', 
                component: TareasAlquilerMaquinariaFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },
            { 
                path: 'duplicar-alquiler-maquinaria/:id', 
                component: TareasAlquilerMaquinariaFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },

            // TAREAS > URBEM
            { path: 'edificios', component: TareasEdificiosComponent,  canActivate: [AuthGuard, ShowTareasMtoGuardService] },    
            { path: 'crear-edificio', component: TareasEdificiosFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { path: 'editar-edificio/:id', component: TareasEdificiosFormComponent, canActivate: [AuthGuard, ShowTareasMtoGuardService]},
            { 
                path: 'duplicar-edificio/:id', 
                component: TareasEdificiosFormComponent, 
                canActivate: [AuthGuard, ShowTareasMtoGuardService]
            },    

            // TAREAS > OTRAS
            {path: 'tareas-otros', component: OtrosComponent, canActivate: [AuthGuard, ShowTareasOtrosGuardService]},
            {path: 'tareas-crear-otra', component: OtrosFormComponent, canActivate: [AuthGuard, ShowTareasOtrosGuardService]},
            {path: 'tareas-editar-otra/:id', component: OtrosFormComponent, canActivate: [AuthGuard, ShowTareasOtrosGuardService]},
            {
                path: 'tareas-editar-otra/:id/:deCalendario', 
                component: OtrosFormComponent, 
                canActivate: [AuthGuard, ShowTareasOtrosGuardService]
            },
            {path: 'tareas-duplicar-otra/:id', component: OtrosFormComponent, canActivate: [AuthGuard, ShowTareasOtrosGuardService]},

            // TAREAS > CONTROL DE RIEGO
            {path: 'control-riego', component: ControlRiegoComponent, canActivate: [AuthGuard, ShowControlRiegoGuardService]},

            // TAREAS > CONTROL SANITARIO
            {path: 'control-sanitario', component: ControlSanitarioComponent, canActivate: [AuthGuard, ShowControlSanitarioGuardService]},
            {
                path: 'control-crear-sanitario', 
                component: ControlSanitarioFormComponent, 
                canActivate: [AuthGuard, ShowControlSanitarioGuardService]
            },
            {
                path: 'control-editar-sanitario/:id', 
                component: ControlSanitarioFormComponent, 
                canActivate: [AuthGuard, ShowControlSanitarioGuardService]
            },
            {
                path: 'control-duplicar-sanitario/:id', 
                component: ControlSanitarioFormComponent, 
                canActivate: [AuthGuard, ShowControlSanitarioGuardService]
            },

            //TAREAS FITO EN LOCALES
            {path: 'fito-locales', component: FitoLocalesComponent, canActivate: [AuthGuard, ShowFitoLocalesGuardService]},
            {path: 'fito-crear-locales', component: FitoLocalesFormComponent, canActivate: [AuthGuard, ShowFitoLocalesGuardService]},
            {path: 'fito-editar-locales/:id', component: FitoLocalesFormComponent, canActivate: [AuthGuard, ShowFitoLocalesGuardService]},
            {path: 'fito-duplicar-locales/:id', component: FitoLocalesFormComponent, canActivate: [AuthGuard, ShowFitoLocalesGuardService]},

            
            // TAREAS > SIEMBRA
            {path: 'tareas-siembra', component: SiembraComponent, canActivate: [AuthGuard, ShowTareasSiembraGuardService]},
            {path: 'tareas-crear-siembra', component: SiembraFormComponent, canActivate: [AuthGuard, ShowTareasSiembraGuardService]},
            {path: 'tareas-editar-siembra/:id', component: SiembraFormComponent, canActivate: [AuthGuard, ShowTareasSiembraGuardService]},
            {
                path: 'tareas-editar-siembra/:id/:deCalendario', 
                component: SiembraFormComponent, 
                canActivate: [AuthGuard, ShowTareasSiembraGuardService]
            },
            {path: 'tareas-duplicar-siembra/:id', component: SiembraFormComponent, canActivate: [AuthGuard, ShowTareasSiembraGuardService]},

            
            // TAREAS > AGROAMBIENTALES
            {path: 'tareas-agroambientales', component: AgroambientalesComponent, canActivate: [AuthGuard, ShowAgroambientalGuardService]},
            {
                path: 'tareas-crear-agroambiental', 
                component: AgroambientalesFormComponent, 
                canActivate: [AuthGuard, ShowAgroambientalGuardService]
            },
            {
                path: 'tareas-editar-agroambiental/:id', 
                component: AgroambientalesFormComponent, 
                canActivate: [AuthGuard, ShowAgroambientalGuardService]
            },
            {
                path: 'tareas-duplicar-agroambiental/:id', 
                component: AgroambientalesFormComponent, 
                canActivate: [AuthGuard, ShowAgroambientalGuardService]
            },
            
            // TAREAS > AGROAMBIENTALES
            {path: 'tareas-ecorregimenes', component: EcorregimesComponent, canActivate: [AuthGuard, ShowEcorregimesGuardService]},
            {
                path: 'tareas-crear-ecorregimenes', 
                component: EcorregimesFormComponent, 
                canActivate: [AuthGuard, ShowEcorregimesGuardService]
            },
            {
                path: 'tareas-editar-ecorregimenes/:id', 
                component: EcorregimesFormComponent, 
                canActivate: [AuthGuard, ShowEcorregimesGuardService]
            },
            {
                path: 'tareas-duplicar-ecorregimenes/:id', 
                component: EcorregimesFormComponent, 
                canActivate: [AuthGuard, ShowEcorregimesGuardService]
            },

            // PRODUCTOS > FITOSANITARIOS
            {path: 'productos-fitosanitarios', component: ProductosComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'crear-producto-fitosanitario', component: ProductosFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'editar-producto-fitosanitario/:id', component: ProductosFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'duplicar-producto-fitosanitario/:id', component: ProductosFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},

            // PRODUCTOS > FERTILIZANTES
            {path: 'productos-fertilizantes', component: ProductoFertilizanteComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'crear-producto-fertilizante', component: ProductoFertilizanteFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'editar-producto-fertilizante/:id', component: ProductoFertilizanteFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},

            {
                path: 'consultas', 
                component: ProductosConsultasComponent, 
                canActivate: [AuthGuard, ShowVademecumGuardService]
            },
            {
                path: 'consultas/:num_reg', 
                component: ProductosConsultasComponent, 
                canActivate: [AuthGuard, ShowVademecumGuardService]
            },

            // PRODUCTOS > GANADERIA    
            {path: 'productos-ganaderia', component: ProductoGanaderiaComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {
                path: 'crear-producto-ganaderia', 
                component: ProductoGanaderiaFormComponent, 
                canActivate: [AuthGuard, ShowProductosGuardService]
            },
            {
                path: 'editar-producto-ganaderia/:id', 
                component: ProductoGanaderiaFormComponent, 
                canActivate: [AuthGuard, ShowProductosGuardService]
            },


            // PRODUCTOS > SIMPLE
            {path: 'productos-s', component: ProductosSimpleComponent, canActivate: [AuthGuard,
                ShowProductosSimpleGuardService]},
            {path: 'crear-producto-s', component: ProductosSimpleFormComponent, canActivate: [AuthGuard,
                ShowProductosSimpleGuardService]},
            {path: 'editar-producto-s/:id', component: ProductosSimpleFormComponent, canActivate: [AuthGuard,
                ShowProductosSimpleGuardService]},

            //  PRODUCTOS > BIO
            {path: 'productos-bio', component: ProductosBioComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'crear-producto-bio', component: ProductoBioFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},
            {path: 'editar-producto-bio/:id', component: ProductoBioFormComponent, canActivate: [AuthGuard,
                ShowProductosGuardService]},

            // TRATAMIENTOS
            {path: 'tratamientos', component: TratamientosComponent, canActivate: [AuthGuard]},
            {path: 'crear-tratamiento', component: TratamientosFromComponent, canActivate: [AuthGuard]},
            {path: 'editar-tratamiento/:id', component: TratamientosFromComponent, canActivate: [AuthGuard]},
            {path: 'duplicar-tratamiento/:id', component: TratamientosFromComponent, canActivate: [AuthGuard]},

            // TRATAMIENTOS > CONTROL
            {path: 'tratamientos-control', component: TratamientosControlComponent,
                canActivate: [AuthGuard, ShowTratamientosControlGuardService]},
            {path: 'crear-control-tratamiento', component: TratamientosControlFormComponent,
                canActivate: [AuthGuard, ShowTratamientosControlGuardService]},
            {path: 'crear-control-tratamiento/:finca/:fecha', component: TratamientosControlFormComponent,
                canActivate: [AuthGuard, ShowTratamientosControlGuardService]},
            {path: 'editar-control-tratamiento/:id', component: TratamientosControlFormComponent,
                canActivate: [AuthGuard, ShowTratamientosControlGuardService]},

            // TRATAMIENTOS (MÁÑEZ Y LOZANO)
            {path: 'tratamientos-manez', component: TrataManezComponent, canActivate: [AuthGuard,
                ShowTratamientosManezGuardService]},
            {path: 'crear-tratamiento-manez', component: TrataManezFormComponent, canActivate: [AuthGuard,
                ShowTratamientosManezGuardService]},
            {path: 'editar-tratamiento-manez/:id', component: TrataManezFormComponent, canActivate: [AuthGuard,
                ShowTratamientosManezGuardService]},
            {path: 'duplicar-tratamiento-manez/:id', component: TrataManezFormComponent, canActivate: [AuthGuard,
                ShowTratamientosManezGuardService]},

            // VISITAS
            {path: 'seguimiento', component: VisitasViverospereiraComponent, canActivate: [AuthGuard, ShowVisitasSeguimientoGuardService]},
            {
                path: 'crear-seguimiento', 
                component: ViverosPereiraFormComponent, 
                canActivate: [AuthGuard, ShowVisitasSeguimientoGuardService]
            },
            {
                path: 'editar-seguimiento/:id', 
                component: ViverosPereiraFormComponent, 
                canActivate: [AuthGuard, ShowVisitasSeguimientoGuardService]
            },
            
            
            {path: 'visitas', component: VisitasComponent, canActivate: [AuthGuard, ShowVisitasGuardService]},
            {path: 'editar-visita/:id', component: VisitasFormComponent, canActivate: [AuthGuard, ShowVisitasGuardService]},
            {path: 'visitas-consultas', component: VisitasConsultasComponent, canActivate: [AuthGuard, ShowVisitasConsultasGuardService]},
            {path: 'visitas-variedad-mes', component: VisitasVariedadMesComponent,
                canActivate: [AuthGuard, ShowVisitasVariedadMesGuardService]},

            // VISITAS SEMANA Y VISITAS TRASIEGO
            {path: 'visitas-semana', component: VisitasSemanaComponent, canActivate: [AuthGuard, ShowVisitasSemanaGuardService]},
            {path: 'editar-visita-semana/:id', component: VisitasSemanaFormComponent,
                canActivate: [AuthGuard, ShowVisitasSemanaGuardService]},
            {path: 'visitas-trasiego', component: VisitasTrasiegoComponent, canActivate: [AuthGuard, ShowVisitasSemanaGuardService]},
            {path: 'editar-visita-trasiego/:id', component: VisitasTrasiegoFormComponent,
                canActivate: [AuthGuard, ShowVisitasSemanaGuardService]},
            {path: 'visitas-pedidos', component: VisitasPedidosComponent, canActivate: [AuthGuard]},
            {path: 'crear-visita-pedido', component: VisitasPedidosFormComponent, canActivate: [AuthGuard]},
            {
                path: 'editar-visita-pedido/:id', 
                component: VisitasPedidosFormComponent, 
                canActivate: [AuthGuard, ShowVisitasPedidosGuardService]
            },
            {
                path: 'duplicar-visita-pedido/:id', 
                component: VisitasPedidosFormComponent, 
                canActivate: [AuthGuard, ShowVisitasPedidosGuardService]
            },

            // COSTES
            {path: 'costes-fitosanitarios', component: CostesFitosanitariosComponent, canActivate: [AuthGuard, ShowCostesGuardService]},
            {path: 'costes-fertilizantes', component: CostesFertilizantesComponent, canActivate: [AuthGuard, ShowCostesGuardService]},
            {path: 'costes-tareas', component: CostesTareasComponent, canActivate: [AuthGuard, ShowCostesGuardService]},
            {path: 'costes-productos', component: CostesProductosComponent, canActivate: [AuthGuard, ShowACostesProductosGuardService]},

            // STOCK
            { path: 'stock', component: StockComponent, canActivate: [AuthGuard, ShowStockGuardService]},

            { path: 'stock-entradas', component: EntradasComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-crear-entrada', component: EntradasFormComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-editar-entrada/:id', component: EntradasFormComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-duplicar-entrada/:id', component: EntradasFormComponent, canActivate: [AuthGuard, ShowStockGuardService]},
      
            { path: 'stock-salidas', component: SalidasComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-crear-salida', component: SalidasFormComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-editar-salida/:id', component: SalidasFormComponent, canActivate: [AuthGuard, ShowStockGuardService]},
      
            { path: 'stock-balances', component: BalancesComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-productos', component: StockProductosComponent, canActivate: [AuthGuard, ShowStockGuardService]},
            { path: 'stock-productos-form', component: StockFormularioProductosComponent, canActivate: [AuthGuard, ShowStockGuardService]},

            // RETO
            { path: 'reto', component: RetoComponent, canActivate: [AuthGuard, ShowRetoGuardService]},

            { path: 'reto-entidades', component: EntidadesComponent, canActivate: [AuthGuard, ShowRetoGuardService]},
            { path: 'reto-crear-entidades', component: EntidadesFormComponent, canActivate: [AuthGuard, ShowRetoGuardService]},
            { path: 'reto-editar-entidades/:id', component: EntidadesFormComponent, canActivate: [AuthGuard, ShowRetoGuardService]},

            { path: 'reto-transacciones', component: TransaccionesComponent, canActivate: [AuthGuard, ShowRetoGuardService]},
            { path: 'reto-crear-transacciones', component: TransaccionesFormComponent, canActivate: [AuthGuard, ShowRetoGuardService]},
            { path: 'reto-editar-transacciones/:id', component: TransaccionesFormComponent, canActivate: [AuthGuard, ShowRetoGuardService]},
            { 
                path: 'reto-duplicar-transacciones/:id', 
                component: TransaccionesFormComponent, 
                canActivate: [AuthGuard, ShowRetoGuardService]
            },
            { path: 'colsultas-logs', component: ConsultasLogsComponent, canActivate: [AuthGuard, ShowUsuariosLogService ] },
        ]
    },
    {path: '**', redirectTo: 'login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
