import { AlbaranApiService } from "../../../../service/api/albaran-api.service";
import { createCsv } from "src/app/common/createCsv";
import { environment } from "../../../../../environments/environment";
import { Component, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardService } from "../../../../service/events/dashboard.service";
import { InputType } from "../../../../common/components/form-builder/form-builder.component";
import { RequestHandler } from "../../../../service/OffService/request-handler";
import { RequestButtonTypes } from "../../../../common/components/request-button/request-button.component";
import { Filtering } from "../../../../service/filtering/filtering";
import { Utils } from "../../../../common/utils";
import { BaseView } from "../../../base-view";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { StorageManager } from "../../../../common/storage-manager.class";
import moment from "moment";
// import { MessageService } from 'primeng/api';

import type { OnDestroy, OnInit, AfterViewInit } from "@angular/core";
import type { ItemInterface } from "../../../../common/components/form-builder/form-builder.component";
import { AlbaranModel } from "src/app/models/ventas/albaran.model";
import { ColInterface } from "src/app/common/components/common-list-table/col.interface";
import { ColStylesInterface } from "src/app/common/components/common-list-table/col-styles.interface";

@Component({
  selector: "app-albaranes",
  templateUrl: "./albaranes.component.html",
  styleUrls: ["./albaranes.component.scss"],
})
export class AlbaranesComponent
  extends BaseView<AlbaranModel>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("passport", { static: true }) passport: ElementRef | undefined;

  public showBtnTratamiento = environment.features.showBtnTratamiento;
  public years = [{ label: "...", value: 0 }];
  public selectedYears = 0;

  public rolUser = StorageManager.getUser().rol;
  public hiddenButtonDelete = false;
  public appName: string = environment.appName;
  public serverUrl = environment.serverUrl;
  public logoUrl: string = environment.logoUrl;
  public showEnviarWhatsapp = environment.features.showEnviarWhatsapp;
  public showEnviarTelegram = environment.features.showEnviarTelegram;
  public showEnviarCorreo = environment.features.showEnviarCorreo;
  public showPrescripcionTratamiento =
    environment.features.showPrescripcionTratamiento;
  public showRecetaGlobalGAP = environment.features.showRecetaGlobalGAP;

  public tooltipForce = false;
  public tooltipClass = this.tooltipForce
    ? "telegram-passport-tooltip telegram-passport-tooltip-shown"
    : "telegram-passport-tooltip";

  public textoSuperficie = environment.features.textoSuperficie;
  public betweenDates = environment.features.showTareasFitoBetweenDates;

  public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;

  public empresaName = environment.features.empresaName;
  public canAddTareas = environment.features.canAddTareas;
  public canDuplicateTareas = environment.features.canDuplicateTareas;
  public hasClients = environment.features.hasClients;
  public userCanSeeClientes =
    this.hasClients &&
    (environment.features.userCanSeeClientes
      ? true
      : StorageManager.getUser().rol === "admin");
  public rol = StorageManager.getUser().rol;

  public showUsuariosFirma = environment.features.showUsuariosFirma;
  public showClientesFirma = environment.features.showClientesFirma;
  public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;
  public showTareasFitoSoleado = environment.features.showTareasFitoSoleado;
  public showTareasFitoLitrosAplicados =
    environment.features.showTareasFitoLitrosAplicados;
  public showTareasFitoMochila = environment.features.showTareasFitoMochila;
  public showTareasFitoAplicacion =
    environment.features.showTareasFitoAplicacion;
  public showClientesNotas = environment.features.showClientesNotas;

  public isGeneratingCanvas: boolean = false;
  public firmaPath: string = "";

  public getRequest: RequestHandler<AlbaranModel[]> = this.albaranesApi.albaranesGET;
  public deleteRequest: RequestHandler<AlbaranModel[]> = this.albaranesApi.albaranesDELETE;
  public pathToForm = "crear-albaran";
  public menuItemId = "albaranes";
  public aplicado = {
    selected: "",
  };
  public validado = {
    selected: "",
  };

  public deleteRequestButtonType: RequestButtonTypes =
    RequestButtonTypes.DELETE;

  public override canShow: { [key: string]: any } = {};
  public override model: any = {};

  public showReceta = false;
  public isShowingGlobalGAP = false;
  public receta: any;
  public currentUser = StorageManager.getUser();
  public clienteSelected = "";
  public telefClienteSelected = "";
  public observaciones = "";
  public empresa = "Locatec Aplicaciones Informáticas";

  public override filtering: Filtering<any> = new Filtering<any>();
  public currentYear: string = "";

  public btnAnadir = "Añadir Albarán";

  public distributionOrder = [4];

  public nameReceta: string =
    environment.features.showRecetaPascualCabedo &&
    this.appName !== "gruesnadal"
      ? "Parte de trabajo"
      : "Generar Albarán";

  public sm = localStorage.getItem("sm") ? true : false;

  public albaranInfo: ItemInterface<any>[] = [
    {
      field: "id",
      name: "id",
      label: "Nº Referencia",
      inputType: { type: InputType.TEXT },
    },

    {
      field: "chofer_nombre",
      name: "chofer",
      label: "Chofer",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "fecha",
      name: "fecha",
      label: "Fecha",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "direccion",
      name: "direccion",
      label: "Dirección",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "cif",
      name: "cif",
      label: "CIF",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "cliente_nombre",
      name: "cliente",
      label: "Cliente",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "poblacion",
      name: "poblacion",
      label: "Población",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "cp",
      name: "cp",
      label: "Código Postal",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "telefono",
      name: "telefono",
      label: "Teléfono",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "correo",
      name: "correo",
      label: "Correo",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "obra_nombre",
      name: "obra",
      label: "Obra",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "maquinaria_nombre",
      name: "maquinaria",
      label: "Maquinaria",
      inputType: { type: InputType.TEXT },
    },
  ];

  public albaranMaterialesInfo: ItemInterface<any>[] = this.autoFill();
  private autoFill() {
    var tempArray = [];
    for (let i = 1; i <= 7; i++) {
      tempArray.push(
        {
          field: `cantidad${i}`,
          name: `cantidad${i}`,
          label: `Cantidad ${i}`,
          inputType: { type: InputType.TEXT },
        },
        {
          field: `concepto${i}`,
          name: `concepto${i}`,
          label: `Concepto ${i}`,
          inputType: { type: InputType.TEXT },
        },
        {
          field: `precio${i}`,
          name: `precio${i}`,
          label: `Precio ${i}`,
          inputType: { type: InputType.TEXT },
        },
        {
          field: `total${i}`,
          name: `total${i}`,
          label: `Total ${i}`,
          inputType: { type: InputType.TEXT },
        }
      );
    }

    return tempArray;
  }

  public datosAdicionales: ItemInterface<any>[] = [
    {
      field: "trabajos_realizados",
      name: "trabajos_realizados",
      label: "Trabajos realizados",
      inputType: { type: InputType.TEXTAREA },
    },

    {
      field: "conforme",
      name: "conforme",
      label: "Conforme",
      inputType: { type: InputType.TEXTAREA },
    },
  ];

  public totalHoras: any = "00:00";
  public auxTotalHoras: any = "00:00";

  public cols = [
    //{ field: 'referencia_receta', header: 'Orden', filter: '', width: '60px', visible: this.appName === 'pyf' },
    {
      field: "id",
      header: "Nº\xa0Referencia",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
    {
      field: "chofer_nombre",
      header: "Chofer",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
    {
      field: "fecha",
      header: "Fecha",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
    {
      field: "cliente_nombre",
      header: "Cliente",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
    {
      field: "poblacion",
      header: "Poblacion",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
    {
      field: "obra_nombre",
      header: "Obra",
      filter: "",
      width: "160px",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
  ];
  public str: string = "";

  public modalShowCampaign = false;

  public digitosDecimalesDosis: number =
    environment.appName === "vginer" ? 3 : 2;
  public parts: any[] = [];

  public fecha: { inicio: any; fin: any } = {
    inicio: "",
    fin: "",
  };

  constructor(
    public albaranesApi: AlbaranApiService,
    private dashboard: DashboardService,
    private router: Router // private messageService: MessageService
  ) {
    super(dashboard, albaranesApi.albaranesGET);
  }

  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
    console.log('Starting Module...');
    
  }

  ngOnInit() {
    if (environment.features.showTareasMto) {
      if (this.rolUser !== "admin") {
        this.hiddenButtonDelete = true;
      }
    }
    if (this.appName === "ava") {
      this.aplicado.selected = "no-aplicados";
    }

    this.dashboard.setSubmenuItem(this.menuItemId);

    this.expandFormFields();

    this.appName = environment.appName;

    if (this.empresaName !== "") {
      this.empresa = this.empresaName;
    }

    if (this.appName === "melia") {
      this.empresa = this.currentUser.apellidos;
    }

    this.dashboard.setHeaderTitle("Albaranes");
  }

  decode(utftext: string) {
    let string = "",
      i = 0,
      c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        // eslint-disable-next-line no-bitwise
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        // eslint-disable-next-line no-bitwise
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }

    return string;
  }

  modalCampaign() {
    this.modalShowCampaign = true;
  }

  closeModalShowCampaign() {
    this.modalShowCampaign = false;
  }

  selectedCampaign() {
    this.filtering.filter();
  }

  compareDates(row: any) {
    const auxFecha = (row["fecha_inicio"] as string).split("/");
    const nuevaFecha = moment(
      auxFecha[2] + "-" + auxFecha[1] + "-" + auxFecha[0]
    );
    const fechaComparar = moment(
      "20" + this.selectedYears.toString() + "-07" + "-01"
    );
    const fechaMax = moment(
      "20" + (this.selectedYears + 1).toString() + "-06" + "-30"
    );

    if (nuevaFecha > fechaComparar && nuevaFecha < fechaMax) {
      return true;
    } else {
      return false;
    }
  }

  public add() {
    this.router.navigate(["dashboard", this.pathToForm]);
  }

  public edit(data: any) {
    this.router.navigate(["dashboard", "editar-albaran", data.data.id]);
  }

  public duplicate(data: any) {
    this.router.navigate(["dashboard", "duplicar-albaran", data.data.id]);
  }

  public override deleteRegister() {
    if (this.model["id"]) {
      this.deleteRequest.safePerform(this.model);
      this.deleteRequest.response(() => {
        if (!this.deleteRequest.hasError) {
          this.getRequest.safePerform();
        }
      });
    } else {
      this.deleteRequest.hasError = true;
      throw new Error("Trying to DELETE a register without primary key");
    }
  }

  public override show(modal: string, data: any) {
    super.show(modal, data.data)
  }

  public override hide(modal: string) {
    this.model = null;
    this.canShow[modal] = false;
    this.deleteRequest.reset();
  }

  public override ngOnDestroy() {
    Utils.unsuscribe([
      this.albaranesApi.albaranesGET,
      this.albaranesApi.albaranesDELETE,
    ]);
  }

  public enviarCorreo() {
    this.isGeneratingCanvas = true;
    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    )
      .then((canvas) => {
        this.isGeneratingCanvas = false;
        const pdf = new jsPDF(config.orientation, "mm", "a4");
        const data = canvas.toDataURL("image/png");

        if (canvas.width > canvas.height) {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            config.width,
            canvas.height / (canvas.width / config.width),
            "alias",
            "FAST"
          );
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            canvas.width / (canvas.height / config.height),
            config.height,
            "alias",
            "FAST"
          );
        }
      })
      .then(
        () => {
          // console.log('Adjuntos generados.');
        },
        () => {
          alert(
            "Ha habido un error al enviar el email. Si este error persiste, por favor, contacte con un administrador."
          );
          // console.log(error);

          this.isGeneratingCanvas = false;
        }
      );
  }

  public enviarWhatsapp() {
    window.open("https://web.whatsapp.com/", "_blank");

    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    ).then((canvas) => {
      this.isGeneratingCanvas = false;

      const pdf = new jsPDF(config.orientation, "mm", "a4");
      const data = canvas.toDataURL("image/png");

      if (canvas.width > canvas.height) {
        if (this.appName === "pyf") {
          pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            config.width,
            canvas.height / (canvas.width / config.width),
            "alias",
            "FAST"
          );
        }
      } else {
        if (this.appName === "pyf") {
          pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            canvas.width / (canvas.height / config.height),
            config.height,
            "alias",
            "FAST"
          );
        }
      }

      const file = pdf.output("blob");

      const formData = new FormData();
      formData.append("document", file, "receta.pdf");
      formData.append("phoneNumber", "+34" + this.receta.cliente_telefono);

      function sendDocument() {
        const url = `${environment.serverUrl}ws/tareas/enviar_receta_whatsapp.php`;

        const xht = new XMLHttpRequest();

        xht.open("POST", url);
        xht.send(formData);
      }

      sendDocument();
    });

    this.showReceta = false;
  }

  public generarReceta(data: any, showAfter = true) {
    this.receta = data.data;

    this.currentYear = new Date().getFullYear().toString();
    this.receta.fecha_completa = moment(
      this.receta.fecha_fin,
      "DD/MM/YYYY"
    ).isValid()
      ? moment(this.receta.fecha_fin, "DD/MM/YYYY").format("LL")
      : "___/___/______";

    if (showAfter) {
      this.showReceta = true;
    }
  }

  public generarGlobalGAP(data: any) {
    this.generarReceta(data, false);

    if (this.showUsuariosFirma) {
      this.firmaPath =
        this.pathToFirmas + "_usuarios/" + this.receta.id_usuario + ".png";

      if (this.receta.usuario_firma !== "") {
        this.firmaPath =
          this.pathToFirmas + "_asesor/" + this.receta.usuario_firma;
      }
    }

    this.isShowingGlobalGAP = true;
  }

  public getPrintConfig() {
    const config: {
      canvasScale: number;
      orientation: "p" | "l";
      width: number;
      height: number;
    } = {
      canvasScale: 2,
      orientation: "p",
      width: 210,
      height: 290,
    };

    switch (this.appName) {
      case "laplana":
        config.canvasScale = 1.04;
        config.width = 197;
        config.height = 290;
        break;
      case "pascualcabedo":
        config.canvasScale = 0.86;
        break;
      case "vginer":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "esparragosgranada":
        config.orientation = "l";
        config.width = 290;
        config.height = 187;
        break;
      case "fitogest":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "agrogest":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "armentia":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "melia":
        config.width = 320;
        config.height = 250;
        break;
      case "saviatropical":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "gragon":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      default:
        break;
    }
    return config;
  }

  public imprimirReceta() {
    this.isGeneratingCanvas = true;
    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    ).then((canvas) => {
      this.isGeneratingCanvas = false;

      const pdf = new jsPDF(config.orientation, "mm", "a4");
      const data = canvas.toDataURL("image/png");

      const padding = 10; // Padding de 10mm solo en horizontal

      // Ajusta el ancho disponible para la imagen restando el padding horizontal
      let imgWidth = config.width - padding * 2;
      let imgHeight = canvas.height / (canvas.width / imgWidth); // Mantiene la proporción de la imagen

      // Cálculo para centrar la imagen solo horizontalmente con padding
      const xOffset = (config.width - imgWidth) / 2; // Desplazamiento horizontal con padding
      const yOffset = 0; // No se aplica ningún desplazamiento vertical (sin padding vertical)

      if (canvas.width > canvas.height) {
        if (this.appName === "pyf") {
          pdf.addImage(
            data,
            "png",
            xOffset,
            yOffset + 20,
            imgWidth,
            140,
            "alias",
            undefined
          );
        } else {
          pdf.addImage(
            data,
            "png",
            xOffset, // Coordenada X centrada con padding horizontal
            yOffset, // Coordenada Y sin padding
            imgWidth,
            imgHeight,
            "alias",
            "FAST"
          );
        }
      } else {
        if (this.appName === "pyf") {
          pdf.addImage(
            data,
            "png",
            xOffset,
            yOffset + 20,
            imgWidth,
            140,
            "alias",
            undefined
          );
        } else {
          pdf.addImage(
            data,
            "png",
            xOffset, // Coordenada X centrada con padding horizontal
            yOffset, // Coordenada Y sin padding
            imgWidth,
            imgHeight,
            "alias",
            "FAST"
          );
        }
      }

      pdf.save(`albarán-${this.receta["id"]}`);
    });
  }

  public enviarTelegram() {
    const idTelegram = StorageManager.getUser().id_telegram;

    if (!idTelegram) {
      const elementPassport = this.passport?.nativeElement;

      const passport = document.createElement("script");
      const key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA72n4YZpEZfgholYLVavB
mtDw3uLgHHxg6Ed6HdNipVPt8pvI+PqFlBlg3Bkpkz+Mxh5GOLy45aF/5AIqk/6A
qk1C5h9j7kgKyAWVlGDvkDiF+CBSrTTdw3CJRog+d1Vm6bvGk7SsUK//aO79u5Fc
9hsidWP0C4ngkK2lOX0+xK/DreZqlSYjt0BecYTV+ldI+Bys5D1TI6365N1SniHB
DjHddB/7DCreFB+MD+ZMcMDq6H59B2kNmI8TI7SHKk3q6x60HajsHXnQBuqm2y5E
TuC1fnwt9yxpLXOTHXxGL0fTuqlv847pLvpBCsZvpug4Zckn/N14hbho9JTbYwD0
fwIDAQAB
-----END PUBLIC KEY-----`;

      const authButton = document.getElementById("telegram_passport_auth");
      passport.text =
        `var auth_button = document.getElementById('telegram_passport_auth');
            var auth_params = {
              bot_id:        6449999161,
              scope:         {data: [{type: 'id_document', selfie: true}, 'address_document', 'phone_number', 'email'], v: 1},
              public_key:    ` +
        "`" +
        key +
        "`" +
        `,
              nonce:         '${environment.serverUrl}',
              callback_url:  '${environment.serverUrl}#/dashboard/fitosanitarios'
            };
            auth_button.addEventListener('click', function() {            
                Telegram.Passport.auth(auth_params, function(show) {
                    if (show) {
                        
                    } else {
                        
                    }
                });
            }
            , false);`;

      elementPassport.parentElement.replaceChild(passport, elementPassport);

      authButton?.click();
      this.tooltipForce = true;
      this.tooltipClass = this.tooltipForce
        ? "telegram-passport-tooltip telegram-passport-tooltip-shown"
        : "telegram-passport-tooltip";
    } else {
      this.isGeneratingCanvas = true;
      const config = this.getPrintConfig();

      html2canvas(
        document.getElementById("html2canvasDiv") ?? new HTMLElement(),
        { scale: config.canvasScale, logging: false }
      ).then((canvas) => {
        this.isGeneratingCanvas = false;

        const pdf = new jsPDF(config.orientation, "mm", "a4");
        const data = canvas.toDataURL("image/png");

        if (canvas.width > canvas.height) {
          if (this.appName === "pyf") {
            pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
          } else {
            pdf.addImage(
              data,
              "png",
              0,
              0,
              config.width,
              canvas.height / (canvas.width / config.width),
              "alias",
              "FAST"
            );
          }
        } else {
          if (this.appName === "pyf") {
            pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
          } else {
            pdf.addImage(
              data,
              "png",
              0,
              0,
              canvas.width / (canvas.height / config.height),
              config.height,
              "alias",
              "FAST"
            );
          }
        }

        const blob = pdf.output("blob");
        const file = new File([blob], "receta.pdf");

        const tg = {
          token: "6449999161:AAFpVGCLY2bF4NAOs98mU4Brw7KVI9hZRBY",
          chat_id: StorageManager.getUser().id_telegram,
        };

        const formData = new FormData();
        formData.append("document", file, "receta.pdf");

        function sendDocument() {
          const url = `https://api.telegram.org/bot${tg.token}/sendDocument?chat_id=${tg.chat_id}`;
          const xht = new XMLHttpRequest();

          xht.open("POST", url);
          xht.send(formData);
        }

        sendDocument();
      });
    }
  }

  public globalConditionalStyle(
    _value: string, col: ColInterface
  ): ColStylesInterface {
    let estiloVademecum: any = {};

    if (col.field === "trabajadores_nombres") {
      return {
        style: {
          "max-width": "50px",
        },
      };
    }
    if (col.field === "observaciones") {
      return {
        style: {
          "max-width": "50px",
        },
      };
    }

    if (environment.features.showTareasMto) {
      if (col.field === "aplicado") {
        if (_value === "0") {
          return {
            rowStyle: {
              backgroundColor: "#f44242",
            },
          };
        } else if (_value === "1") {
          return {
            rowStyle: {
              backgroundColor: "#00ff00",
            },
          };
        }
      }
    }

    // if (Utils.isNumber(value) && col.field !== 'sector' && col.field !== 'facturado') {
    if (col.field !== "sector" && col.field !== "facturado") {
      if (col.field === "id" || col.field === "horas_totales") {
        return { textAlign: "center" };
      } else {
        return { textAlign: "right", paddingRight: "40px" };
      }
    }

    if (["id", "fecha_inicio", "fecha_fin"].includes(col.field)) {
      return { textAlign: "center" };
    }

    return estiloVademecum;
  }

  public globalVisualTransform(
    value: string, col: ColInterface
  ) {
    if (col.field === "parcelas_nombres") {
      return value && value.length > 50 ? value.slice(0, 50 - 1) + "…" : value;
    }
    if (col.field === "eficacia") {
      switch (value) {
        case "1":
          return "Buena";
        case "2":
          return "Regular";
        case "3":
          return "Mala";
        default:
          return value;
      }
    }

    if (col.field === "facturado") {
      switch (value) {
        case "0":
          return "No";
        case "1":
          return "Sí";
        default:
          return "No";
      }
    }

    if (Utils.isNumber(value) && col.field !== "id") {
      if (col.field !== "finca") {
        if (col.field === "litros" && environment.features.showTareasMto) {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            4,
            ".",
            ","
          );
        } else if (col.field === "litros" && this.appName === "onda") {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            0,
            ".",
            ","
          );
        } else {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            2,
            ".",
            ","
          );
        }
      }
    }

    return value;
  }

  public formatDate(date: string) {
    moment.locale("es");
    return moment(date, "DD/MM/YYYY").format("LL");
  }

  public createNavigation(route: string) {
    this.router.navigate(["dashboard", route]);
  }

  public async downloadCsv() {
    const arrayFormFields = [
      this.albaranInfo,
      this.albaranMaterialesInfo,
      this.albaranMaterialesInfo,
    ];
    let campoFinal = "factura_albaran";

    const csvContent = createCsv(
      arrayFormFields,
      this.filtering.value,
      campoFinal
    );

    const csvLink: any = document.getElementById("csvLink");
    csvLink.download = "Fitosanitarios.csv";
    csvLink.href = encodeURI(csvContent);
    csvLink.click();
  } 

  private expandFormFields() {}

  public static createNavigation(router: Router, route: string = "albaranes") {
    router.navigate(["dashboard", route]);
  }
}
