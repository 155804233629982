import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {ProductosApiService} from '../../../../service/api/productos-api';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseView} from '../../../base-view';
import {environment} from '../../../../../environments/environment';
import moment from 'moment';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
import { SdiApiService } from '../../../../service/api/sdi.service';
import { Filtering } from '../../../../service/filtering/filtering';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-productos',
    templateUrl: './productos.component.html',
    styleUrls: ['./productos.component.scss']
})
export class ProductosComponent extends BaseView<ProductosModel> implements OnInit, OnDestroy {

    public showSdiProductos = environment.features.showSdiProductos;
    public canAddProductos = environment.features.canAddProductos;
    public appName = environment.appName;
    public canEditProductos = environment.features.canEditProductos;
    public canDeleteProductos = environment.features.canDeleteProductos;
    public showProductosFitoDosis = environment.features.showProductosFitoDosis;
    public showReto = environment.features.showReto;
    public showProductosMaxAplicacion = environment.features.showProductosMaxAplicacion;

    public showInfoCambios = false;
    public getRequest: RequestHandler<ProductosModel[]> = this.productosApi.productos.GET;
    public getRequestSdi: RequestHandler<ProductosModel[]> = this.sdiApi.SdiProductosGET;
    public deleteRequest: RequestHandler<ProductosModel> = this.productosApi.productos.DELETE;
    public pathToForm = 'productos-form';
    public menuItemId = 'fitosanitarios';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: ProductosModel = {nombre: '', materia_activa: ''};
    public isShowingSdiProductos = false;
    public updateVademecum = false;

    public override filtering: Filtering<ProductosModel> = new Filtering<ProductosModel>();
    public typeFilter: {type: string; mes1: number; mes2: number} = {
        type: '',
        mes1: 0,
        mes2: 0
    };
    public first = 0;

    public tableValues = new RequestHandler();

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Referencia', 
            field: 'num_registro', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plazo Seguridad', 
            field: 'ps', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Materia Activa', 
            field: 'materia_activa', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Otra denominación común',
            field: 'otra_denominacion_comun',
            inputType: {type: InputType.RO_SWITCH}
        },
    ];
    public formFieldBottomLeft: ItemInterface<object>[] = [
        {
            label: 'Precio', 
            field: 'precio', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Uds medida', 
            field: 'unidades', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Envase', 
            field: 'envase', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Dosis', 
            field: 'dosis', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showProductosFitoDosis
        },
        {
            label: 'Nº Máximo aplicaciones',
            field: 'num_maximo_aplicaciones',
            inputType: {type: InputType.TEXT},
            visible: this.showProductosMaxAplicacion
        },
        {
            label: 'Observaciones', 
            field: 'observaciones', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'amoros' 
        }
    ];

    public formFieldsSdi: ItemInterface<object>[] = [
        {
            label: 'Código', 
            field: 'codart', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Descripción', 
            field: 'descart', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Precio de venta', 
            field: 'prcventa', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Número de registro', 
            field: 'sdifit_numregistro', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Composición', 
            field: 'sdifit_composicion', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Tipo de producto', 
            field: 'sdifit_tipoproducto', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Nombre', 
            field: 'sdiadr_nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Texto', 
            field: 'texto', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Nombre comercial', 
            field: 'sdifit_nombrecomercial', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public cols: ColInterface[] = [
        {field: 'num_registro', header: 'N˚ Registro', width: '100px'},
        {field: 'nombre', header: 'Nombre', filter: '', width: '250px'},
        {field: 'materia_activa', header: 'Materia Activa', width: '240px'},
        {field: 'ps', header: 'Plazo Seg.', filter: '', width: '120px'},
        {field: 'precio', header: 'Precio', filter: '', width: '80px', visible: environment.features.showPrecio},
        {field: 'unidades', header: 'Unidades', filter: '', width: '80px'},
        {field: 'envase', header: 'Envase', filter: '', width: '80px'},
        {field: 'fecha_caducidad', header: 'Fecha Caducidad', width: '140px'},
    ];

    public colsSdi: ColInterface[] = [
        // {field: 'codart', header: 'Código', filter: '', width: '60px'},
        {field: 'descart', header: 'Descripción', filter: ''},
        {field: 'prcventa', header: 'Precio de venta', filter: ''},
        {field: 'sdifit_numregistro', header: '# Registro', filter: '', width: '80px'},
        {field: 'sdifit_composicion', header: 'Composición', filter: ''},
        {field: 'sdifit_tipoproducto', header: 'Tipo de producto', filter: ''},
        {field: 'sdiadr_nombre', header: 'Envase', filter: ''},
        {field: 'texto', header: 'Texto', filter: ''},
        {field: 'sdifit_nombrecomercial', header: 'Nom. comercial', filter: ''}
    ];

    constructor(public productosApi: ProductosApiService,
        public sdiApi: SdiApiService,
        private dashboardEvents: DashboardService,
        private router: Router,
        private formApi: FormCommonApiService) {
        super(dashboardEvents, productosApi.productos.GET);
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('productos-fitosanitarios');
        this.tableValues.isLoading = true;
        this.productosApi.productos.GET.safePerform();
        this.productosApi.productos.GET.response(data => {
            this.loadRequests(data);
            this.tableValues.isLoading = false;
            this.tableValues.emitResponse(data.filter((item: ProductosModel) => {
                if (localStorage.getItem('sm') != null && item.num_registro != null){
                    return item.tipo === 'fitosanitario';
                }
                else if (localStorage.getItem('sm') == null){
                    return item.tipo === 'fitosanitario';
                } else {
                    return;
                }
            }));
        });
        this.initFilter();
    }

    async loadRequests(productos: ProductosModel[]) {
        const requests: ProductosModel[] = [];
        const product: ProductosModel[] = await this.formApi.magramaAll.toPromise();
        const productoDato = await this.formApi.data.toPromise();
        requests.push(product[0] ?? {} as ProductosModel);
        requests.push(productoDato[0] ?? {} as ProductosModel);
        Promise.all(requests).then(response => {
            const data = Array.from(response);
            const fechaActualizacionMagrama = data[1]?.fecha_actualizacion_magrama;
            // Productos del Vademecum (de los cuales sacamos los códigos y fechas de actualización):
            productos.forEach((producto: ProductosModel) => {
                if (data[0]) {
                    const productoVademecum = data.find((it: ProductosModel) => it.referencia === producto.num_registro);
                    producto.has_actualizacion =
                        (productoVademecum && (productoVademecum.fecha_actualizacion === fechaActualizacionMagrama)) ?? false;
                }
            });

            // Productos del cliente
            for (const producto of productos) {
                const repes = data.filter((it: ProductosModel) => it.num_registro === producto.num_registro) || [];
                if (repes.length > 1) {
                    const index = data.map((it: ProductosModel) => it.num_registro).indexOf(repes[repes.length - 1]?.num_registro);
                    data.splice(index, (repes.length - 1));
                }
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    public goToVademecum(num_reg: number) {
        this.router.navigate(['dashboard', 'consultas', num_reg.toString()]);
    }

    public addProducto() {
        this.router.navigate(['dashboard', 'crear-producto-fitosanitario']);
    }

    public edit(data: ProductosModel) {
        this.router.navigate(['dashboard', 'editar-producto-fitosanitario', data.id]);
    }

    public duplicate(data: ProductosModel) {
        this.router.navigate(['dashboard', 'duplicar-producto-fitosanitario', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform({tipo: 'fitosanitario'} as never);
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public showDetalle(data: ProductosModel) {
        this.show('detalle', data, false);
        this.model.usos = [];
        this.formApi.magramaDetalleProducto.cancelablePerform({p3: '' + (data.num_registro) + ''} as never);
        this.formApi.magramaDetalleProducto.response(usos => {
            if ((usos || []).length > 0) {
                this.model['usos'] = usos;
            }
        });

        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);

    }

    public override show(modal: string, data: ProductosModel, closePreviousModal: boolean = true) {
        let x;

        if ( this.showReto ) {
            data.otra_denominacion_comun = TRUTHY_VALUES.includes( data.otra_denominacion_comun ?? false);
        }
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x] && closePreviousModal) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.canShow[modal] = false;
        if (modal !== 'detalle') {
            this.model = {nombre: '', materia_activa: ''};
            this.deleteRequest.reset();
        }
    }

    public globalConditionalStyle(
        value: string, 
        col: ColInterface, 
        _colGroup: ColInterface[], 
        row: TreeNode<ProductosModel>
    ): ColStylesInterface {
        const producto = row.data as ProductosModel;
        if (Utils.isNumber(value) && col.field !== 'num_registro') {
            if (col.field === 'ps') { return { textAlign: 'right', paddingRight: '40px' }; }
            else {
                return { textAlign: 'right' };
            }
        }
        if (col.field === 'ps') { return { textAlign: 'right', paddingRight: '40px' }; }

        let estiloVademecum: ColStylesInterface = {};
        if (col.field === 'fecha_caducidad') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                },
                textAlign: 'center'
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(producto.fecha_inscripcion ?? ''), 'months');

            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            }

            const diff = moment(new Date()).diff(Utils.toDate(producto.fecha_caducidad ?? ''), 'months');

            if (diff > 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff > 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            } else if (diff >= 0) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffcf78',
                        borderBottom: '1px solid #dddddd'
                    },
                    textAlign: 'center'
                };
            }
        }


        return estiloVademecum;
    }

    switchSdi() {
        this.isShowingSdiProductos = !this.isShowingSdiProductos;
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (Utils.isNumber(value)){
            if (col.field === 'ps' || col.field === 'envase') { return Utils.decimalFormat(value, 0); }
            else if (col.field !== 'num_registro') { return Utils.decimalFormat(value, 2, '.', ',', 3); }
        }
        return value;
    }

    public initFilter() {
        this.filtering.addFilter((data: TreeNode<ProductosModel>[] | void) => this.typeFilter.type
            ? (data as TreeNode<ProductosModel>[])
                .filter(it =>
                    (moment(new Date()).diff(Utils.toDate((it as ProductosModel)[this.typeFilter.type]?.toString() ?? ''), 'months') 
                    >= this.typeFilter.mes1) &&
                        (moment(new Date()).diff(Utils.toDate((it as ProductosModel)[this.typeFilter.type]?.toString() ?? ''), 'months') 
                        < this.typeFilter.mes2)
                )
                .sort(
                    Filtering.sort(this.typeFilter.type, 1)
                )
            : data);
    }

    public myFilter(type: string, mes1: number, mes2: number) {
        this.typeFilter.type = type;
        this.typeFilter.mes1 = mes1;
        this.typeFilter.mes2 = mes2;
        this.filtering.filter();
    }
}

