<div style="max-width: auto; overflow: auto">
  <div #passport></div>
  <app-common-list-table
    [request]="getRequest"
    [cols]="cols"
    [filtering]="filtering"
    [globalConditionalStyle]="globalConditionalStyle"
    [globalVisualTransform]="globalVisualTransform"
    [actionButtonText]="canAddTareas ? btnAnadir : ''"
    (actionButtonClick)="add()"
  >
    <ng-template #thEnd>
      <th>
        <p class="mb-0 d-flex justify-content-center">Acciones</p>
      </th>
      <th style="width: 100px">
        <p class="mb-0 d-flex justify-content-center">Albarán</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          [hideDelete]="hiddenButtonDelete"
          [showDuplicate]="canDuplicateTareas"
          (duplicateRowButtonEvent)="duplicate(data)"
          (editRowButtonEvent)="edit(data)"
          (deleteRowButtonEvent)="show('delete', data)"
          (showRowButtonEvent)="show('info', data)"
        >
        </app-action-buttons>
      </td>
      <td>
        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-center align-items-center">
            <div
              class="d-flex justify-content-center align-items-center"
              (click)="generarReceta(data)"
              tooltipPosition="top"
              pTooltip="PDF"
              style="
                height: 20px;
                width: 20px;
                border-radius: 3px;
                background-color: #007b00;
                color: white;
                cursor: pointer;
              "
            >
              <i class="fa fa-file-pdf-o"></i>
            </div>
          </div>
        </div>
      </td>
    </ng-template>
    <ng-template
      #footer
      let-col
      *ngIf="
        [
          'donjardin',
          'coliberica',
          'demogardengest',
          'fotoagricultura'
        ].includes(this.appName)
      "
    >
      <div
        style="font-size: 0.75em; text-align: left"
        *ngIf="['id'].includes(col.field)"
      >
        TOTAL:
      </div>
      <div
        *ngIf="['horas_totales'].includes(col.field)"
        style="
          font-size: 0.75em;
          text-align: right;
          font-weight: bold;
          padding-right: 34px;
        "
      >
        {{ this.totalHoras || 0 }}
      </div>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  header="Detalles del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['info']" style="width: 80vw !important">
    <div class="mb-5 col-12">
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="albaranInfo"
      >
      </app-form-builder>
      <strong>Materiales</strong>
      <app-form-builder
        [model]="model"
        [distributionColumns]="4"
        [fields]="albaranMaterialesInfo"
      >
      </app-form-builder>
      <strong>Datos Adicionales</strong>
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="datosAdicionales"
      >
      </app-form-builder>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <div class="mb-5 col-12">
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="albaranInfo"
      >
      </app-form-builder>
      <strong>Materiales</strong>
      <app-form-builder
        [model]="model"
        [distributionColumns]="4"
        [fields]="albaranMaterialesInfo"
      >
      </app-form-builder>
      <strong>Datos Adicionales</strong>
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="datosAdicionales"
      >
      </app-form-builder>
    </div>
    <br />
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
        (click)="deleteRegister()"
        [request]="deleteRequest"
        [mode]="deleteRequestButtonType"
      >
      </app-request-button>
    </div>
    <br />
    <p
      *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
      style="color: red; text-align: center"
    >
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br />
</p-dialog>

<!-- VERSIÓN "Prescipción" -->
<p-dialog
  *ngIf="showPrescripcionTratamiento"
  header="Prescripción de Tratamiento"
  [(visible)]="showReceta"
  (onShow)="scrollToTop()"
>
  <div
    *ngIf="showReceta"
    style="margin-left: 20px; margin-right: 20px; width: 80vw !important"
  >
    <div id="html2canvasDiv">
      <font color="white" size="2">{{ str }}</font>

      <table
        width="100%"
        style="font-size: 12; border: 1px solid; border-collapse: collapse"
      >
        <tr style="border: 1px solid; border-collapse: collapse">
          <td>ALBARAN N.: {{ currentYear }}/{{ receta?.id }}</td>
        </tr>
      </table>
      <br />

      <table
        width="100%"
        style="font-size: 12; border: 1px solid; border-collapse: collapse"
      >
        <tr>
          <td style="border-right: 1px solid">Chofer:{{ receta?.chofer }}</td>
          <td style="text-align: right">Fecha: {{ receta?.fecha }}</td>
          <td style="text-align: right">Dirección: {{ receta?.direccion }}</td>
          <td style="text-align: right">CIF: {{ receta?.cif }}</td>
          <td style="text-align: right">Cliente: {{ receta?.cliente }}</td>
          <td style="text-align: right">Población: {{ receta?.poblacion }}</td>
          <td style="text-align: right">Código Postal: {{ receta?.cp }}</td>
          <td style="text-align: right">Teléfono: {{ receta?.telefono }}</td>
          <td style="text-align: right">Correo: {{ receta?.correo }}</td>
          <td style="text-align: right">Obra: {{ receta?.obra }}</td>
        </tr>
      </table>
      <br />

    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="showReceta = false">
        Cerrar
      </button>
      <button
        class="btn btn-primary mx-2"
        (click)="imprimirReceta()"
        [disabled]="isGeneratingCanvas"
      >
        <i class="fa fa-print"></i>
        Imprimir
      </button>
    </div>
  </div>
  <br />
  </div>
</p-dialog>

<p-dialog
  [header]="nameReceta"
  [(visible)]="showReceta"
  (onShow)="scrollToTop()"
>
  <div
    *ngIf="showReceta"
    style="margin-left: 20px; margin-right: 20px; width: 80vw !important"
  >
    <div
      id="html2canvasDiv"
      class="registroDiv mx-auto"
      style="
        font-family: 'Times New Roman', Times, serif;
        font-size: 12pt;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div class="d-flex">
        <img [src]="logoUrl" style="width: 220px; height: auto" />
        <div>
          <strong style="font-size: 25px">GRUES NADAL, S.L.</strong>
          <strong style="font-size: 12px; padding-left: 5px"
            >C.I.F. B03985090</strong
          >
          <br />
          Avda. Alcalde Fdo. Alemany Ortolà, 28 - Ap. Correus 179<br />
          03780 PEGO (Alicante) <br />
          E-mail: gruesnadalsl&#64;gmail.com <br />
          Móbils: Jose - 659 458 882 / Dani - 659 458 881
        </div>
      </div>
      {{receta.data}}

      <table>
        <tbody>
          <tr>
            <td></td>
            <td class="border border-black ps-2">
              <strong>ALBARÁN N°:</strong> {{ receta?.id }}
            </td>
          </tr>
          <tr>
            <td class="border border-black ps-2">
              <strong>Chofer:</strong> {{ receta?.chofer_nombre }}
            </td>
            <td class="border border-black ps-2"><strong>Fecha:</strong> {{ receta?.fecha }}</td>
          </tr>
          <tr>
            <td class="border border-black ps-2" colspan="2">
              <strong>Cliente:</strong> {{ receta?.cliente_nombre }}
            </td>
          </tr>
          <tr>
            <td class="border border-black ps-2">
              <strong>Dirección:</strong> {{ receta?.direccion }}
            </td>
            <td class="border border-black ps-2"><strong>C.I.F.:</strong> {{ receta?.cif }}</td>
          </tr>
          <tr>
            <td class="border border-black ps-2">
              <strong>Población:</strong> {{ receta?.poblacion }}
            </td>
            <td class="border border-black ps-2"><strong>C.P.:</strong> {{ receta?.cp }}</td>
          </tr>

          <tr>
            <td class="border border-black ps-2">
              <strong>Telf.:</strong> {{ receta?.telefono }}
            </td>
            <td class="border border-black ps-2" rowspan="2">
              <strong>OBRA:</strong> {{ receta?.obra_nombre }}
            </td>
          </tr>
          <tr>
            <td class="border border-black ps-2">
              <strong>E-mail:</strong> {{ receta?.correo }}
            </td>
          </tr>
        </tbody>
      </table>

      <table style="margin-top: 25px">
        <thead>
          <td class="border border-black ps-2 text-center"><strong>CANTIDAD</strong></td>
          <td class="border border-black ps-2 text-center"><strong>CONCEPTO</strong></td>
          <td class="border border-black ps-2 text-center"><strong>PRECIO</strong></td>
          <td class="border border-black ps-2 text-center"><strong>TOTAL</strong></td>
        </thead>
        <tbody *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]">
          <tr
            *ngIf="
              receta['cantidad' + i] ||
              receta['concepto' + i] ||
              receta['precio' + i] ||
              receta['total' + i]
            "
          >
            <td class="border border-black ps-2">
              {{ receta["cantidad" + i] }}
            </td>
            <td class="border border-black ps-2">
              {{ receta["concepto" + i] }}
            </td>
            <td class="border border-black ps-2">{{ receta["precio" + i] }}</td>
            <td class="border border-black ps-2">{{ receta["total" + i] }}</td>
          </tr>
        </tbody>
      </table>

      <table style="table-layout: fixed">
        <tbody>
          <tr>
            <td class="border border-black ps-2" colspan="2">
              <strong>Trabajos realizados:</strong> {{ receta?.trabajos_realizados }}
            </td>
          </tr>
          <tr>
            <td class="border border-black ps-2 w-75">
              <strong>Conforme:</strong> {{ receta?.conforme }}
            </td>
            <td class="border border-black ps-2 text-center">
              <strong>Servicio mínimo 2 horas</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="showReceta = false">
        Cerrar
      </button>
      <button
        class="btn btn-primary mx-2"
        (click)="imprimirReceta()"
        [disabled]="isGeneratingCanvas"
      >
        <i class="fa fa-print"></i>
        Imprimir
      </button>
    </div>
  </div>
  <br />
</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed"></a>